export default [
  [
    "fullscreen",
    "source",
    "|",
    "undo",
    "redo",
    "|",
    "bold",
    "italic",
    "underline",
    "fontborder",
    "strikethrough",
    "superscript",
    "subscript",
    "removeformat",
    "formatmatch",
    "autotypeset",
    "blockquote",
    "pasteplain",
    "|",
    "forecolor",
    "backcolor",
    "insertorderedlist",
    "insertunorderedlist",
    "selectall",
    "cleardoc",
    "|",
    "rowspacingtop",
    "rowspacingbottom",
    "lineheight",
    "|",
    "customstyle",
    "paragraph",
    "fontfamily",
    "fontsize",
    "|",
    "directionalityltr",
    "directionalityrtl",
    "indent",
    "|",
    "justifyleft",
    "justifycenter",
    "justifyright",
    "justifyjustify",
    "|",
    "touppercase",
    "tolowercase",
    "|",
    "link",
    "unlink",
    "anchor",
    "|",
    "imagenone",
    "imageleft",
    "imageright",
    "imagecenter",
    "|",
    "simpleupload",
    "insertimage",
    "emotion",
    // "scrawl",
    "insertvideo",
    // "music",
    // "attachment",
    // "map",
    // "gmap",
    // "insertframe",
    "insertcode",
    // "webapp",
    "pagebreak",
    "template",
    "background",
    "|",
    "horizontal",
    "date",
    "time",
    "spechars",
    // "snapscreen",
    // "wordimage",
    "|",
    "inserttable",
    "deletetable",
    "insertparagraphbeforetable",
    "insertrow",
    "deleterow",
    "insertcol",
    "deletecol",
    "mergecells",
    "mergeright",
    "mergedown",
    "splittocells",
    "splittorows",
    "splittocols",
    "charts",
    "|",
    // "print",
    // "preview",
    "searchreplace",
    "drafts",
    "help"
  ]
]
