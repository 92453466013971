import { render, staticRenderFns } from "./MagicCubeBlocks.vue?vue&type=template&id=41018a03&scoped=true"
import script from "./MagicCubeBlocks.vue?vue&type=script&lang=js"
export * from "./MagicCubeBlocks.vue?vue&type=script&lang=js"
import style0 from "./MagicCubeBlocks.vue?vue&type=style&index=0&id=41018a03&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41018a03",
  null
  
)

export default component.exports