// 设置预览项
export const SET_PREVIEW_ITEM = 'SET_PREVIEW_ITEM'
// 移除预览项
export const REMOVE_PREVIEW_ITEM = 'REMOVE_PREVIEW_ITEM'
// 设置预览
export const SET_PREVIEWS = 'SET_PREVIEWS'
// 设置激活项
export const SET_PREVIEW_ACTIVATED = 'SET_PREVIEW_ACTIVATED'
// 设置页面参数
export const SET_PAGE_PARAMS = 'SET_PAGE_PARAMS'
