<template>
  <div class="rich-text-editor">
    <u-editor v-model="$$FormData.content" />
  </div>
</template>

<script>
export default {
  name: 'RichTextEditor'
}
</script>

<style lang="scss" scoped>
.rich-text-editor {
  width: 344px;
  margin: 0 auto;
}
</style>
